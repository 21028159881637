import React from 'react';

import Jauge from './Jauge';

function EtatpesDuProjet() {
  return (
    <div id="etape_du_projet" className="flex flex-col gap-[102px] mt-6">
      <h3 className="text-center text-[40px] font-extrabold text-dark-grey">
        Les étapes de votre <span className="text-bleu-je">projet</span>
      </h3>
      <Jauge />
    </div>
  );
}

export default EtatpesDuProjet;
