import React from 'react';

function EtapeJauge({ numero, text }) {
  return (
    <li className="relative flex flex-col justify-start w-auto child even:top-[250%]">
      <h3 className="text-bleu-je font-semibold text-base">Étape {numero}</h3>
      <p className="text-dark-grey text-[13px] font-normal">{text}</p>
    </li>
  );
}

export default EtapeJauge;
