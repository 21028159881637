import React from 'react';

import BoutonPrincipal from './BoutonPrincipal';

function InfoJuniorDroit({ titre, texte, boutton, image }) {
  return (
    <div className="flex justify-center items-center pt-[80px] mt-[80px]">
      <div className="relative left-[2.6vw] bg-[#F6FAFC] w-[84%] h-[461px] flex flex-row">
        <img src={image} alt="" className="relative right-[2.6vw] -top-[80px] size-[461px] " />
        <div className="relative left-[8.5vw] flex flex-col justify-center items-center gap-3 w-[42vw]">
          <h3 className="text-[#016089] text-3xl font-semibold">{titre}</h3>
          <p className="text-lg font-normal">{texte}</p>
          <BoutonPrincipal titre={'En savoir plus'} lien={boutton} />
        </div>
      </div>
    </div>
  );
}

export default InfoJuniorDroit;
