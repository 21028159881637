import React from 'react';

import './PoleMandat.css';
import CadreMandat from '../components/CadreMandat';

function PoleMandat({ listeMembres, pole }) {
    return (
        <div className="poleMandat">
        <div>
            <h1 className="titrePole">{pole}</h1>
        </div>
        <div className="cadresContainer">
            {listeMembres.map((membre) => (
                <CadreMandat
                    nom={membre.nom}
                    role={membre.role}
                    linkedln={membre.linkedln}
                    mail={membre.mail}
                    photo={membre.photo}
                />
        
            ))}
        </div>
        </div>
    );
}

export default PoleMandat;