import React from 'react';
import { PhotoEquipe, MotDuPresident } from '../components/NotreMandat';
import Header from '../components/Header';
import PoleMandat from '../components/PoleMandat';
import Footer from '../components/Footer';
import { NosValeursHistoire, NotreHistoire } from '../components/NosValeursHistoire';

function Mandat() {
  // Liste des membres du pôle Présidence
  const clement = { nom: 'Clément Ouvry', role: 'Président', linkedln: 'https://www.linkedin.com/in/cl%C3%A9ment-ouvry-a2352b207/', mail: 'couvry@ensc.fr', photo: '/images/PhotosMandat/Clement.jpg' };
  const lou = { nom: 'Lou Marsein', role: 'Secrétaire', linkedln: 'https://www.linkedin.com/in/lou-marsein-2646612a8/', mail: 'lmarsein@ensc.fr', photo: '/images/PhotosMandat/Lou.jpg' };
  const clara = { nom: 'Clara Fanget', role: 'Vice-Présidente', linkedln: 'https://www.linkedin.com/in/clara-fanget-94045029b/', mail: 'cfanget@ensc.fr', photo: '/images/PhotosMandat/Clara.jpg' };
  const listePres = [clement, clara, lou];
  // Liste des membres du pôle DevCo
  const zoe = { nom: 'Zoé Nigron', role: 'Responsable DevCo', linkedln: 'https://www.linkedin.com/in/zoenigron/', mail: 'znigron@ensc.fr', photo: '/images/PhotosMandat/Zoe.jpg' };
  const orelia = { nom: 'Orélia Juan', role: 'Chargée Devco', linkedln: 'https://www.linkedin.com/in/or%C3%A9lia-juan-4777512b1/', mail: 'ojuan@ensc.fr', photo: '/images/PhotosMandat/Orelia.jpg' };
  const adrien = { nom: 'Adrien Delaye', role: 'Chargé DevCo', linkedln: 'https://www.linkedin.com/in/adrien-delaye/', mail: 'adelaye@ensc.fr', photo: '/images/PhotosMandat/Adrien.jpg' };
  const suzon = { nom: 'Suzon Gautheron', role: 'Chargée DevCo', linkedln: 'https://www.linkedin.com/in/suzon-gautheron-732283150/', mail: 'sgautheron@ensc.fr', photo: '/images/PhotosMandat/Suzon.jpg' };
  const lisa = { nom: 'Lisa Fredon', role: 'Chargée DevCo', linkedln: 'https://www.linkedin.com/in/lisa-fredon-5b6b272b2/', mail: 'lfredon@ensc.fr', photo: '/images/PhotosMandat/Lisa.jpg' };
  const listeDevCo = [zoe, orelia, adrien, suzon, lisa];

  //Liste des membres de la tréso
  const esma = { nom: 'Esma Hebbar', role: 'Trésorière', linkedln: 'https://www.linkedin.com/in/esma-hebbar/', mail: 'ehebbar@ensc.fr', photo: '/images/PhotosMandat/Esma.jpg' };
  const elliot = { nom: 'Elliot Greneche', role: 'Vice-Trésorier', linkedln: 'https://www.linkedin.com/in/elliot-greneche/', mail: 'egreneche@ensc.fr', photo: '/images/PhotosMandat/Elliot.jpg' };
  const ana = { nom: 'Ana Sofia Gonzales Patino', role: 'RQT', linkedln: 'https://www.linkedin.com/in/ana-sof%C3%ADa-gonz%C3%A1lez-pati%C3%B1o-ab4a172a5/', mail: 'agonzalezpa@ensc.fr', photo: '/images/PhotosMandat/Ana.jpg' };
  const listeTresorerie = [esma, elliot, ana];
  //Liste du pole qualité
  const axelle = { nom: 'Axelle Agez', role: 'Responsable Qualité', linkedln: 'https://www.linkedin.com/in/axelle-agez/', mail: 'aagez@ensc.fr', photo: '/images/PhotosMandat/Axelle.jpg' };
  const anaelle = { nom: 'Anaelle Jaffré', role: 'Chargée qualité', linkedln: 'https://www.linkedin.com/in/anaelle-jaffr%C3%A9-441a981a2/', mail: 'ajaffre@ensc.fr', photo: '/images/PhotosMandat/Anaelle.jpg' };
  const listeQualite = [axelle, ana, anaelle];
  //Liste du pole communication
  const alexia = { nom: 'Alexia Ille', role: 'Responsable Com', linkedln: 'https://www.linkedin.com/in/alexia-ilie-593418183/', mail: 'aille@ensc.fr', photo: '/images/PhotosMandat/Alexia.jpg' };
  const sofia = { nom: 'Sofia Sojic', role: 'Chargée Com', linkedln: 'https://www.linkedin.com/in/sofia-sojic-997717201/', mail: 'ssojic@ensc.fr', photo: '/images/PhotosMandat/Sofia.jpg' };
  const lise = { nom: 'Lise Amodeo', role: 'Chargée Com', linkedln: 'https://www.linkedin.com/in/lise-amodeo-965777333/', mail: 'lamodeo@ensc.fr', photo: '/images/PhotosMandat/Lise.jpg' };
  const elise = { nom: 'Elise Strub', role: 'Chargée Com', linkedln: 'https://www.linkedin.com/in/elise-strub-10a540286/', mail: 'estrub@ensc.fr', photo: '/images/PhotosMandat/Elise.jpg' };
  const theo = { nom: 'Theo Lereuil', role: 'Chargé Com', linkedln: 'https://www.linkedin.com/in/th%C3%A9o-lereuil-775159243/', mail: 'tlereuil@ensc.fr', photo: '/images/PhotosMandat/Theo.jpg' };
  const agathe = { nom: 'Agathe Lepri', role: 'Responsable Event', linkedln: 'https://www.linkedin.com/in/agathe-lepri-0ba0742aa/', mail: 'alepri@ensc.fr', photo: '/images/PhotosMandat/Agathe.jpg' };
  const listeCommunication = [alexia, sofia, lise, elise, theo];
  //Liste du pole SI
  const simon = { nom: 'Simon LeTohic', role: 'Responsable SI', linkedln: 'https://www.linkedin.com/in/simon-le-tohic/', mail: 'sletohic@ensc.fr', photo: '/images/PhotosMandat/Simon.jpg' };
  const niang = { nom: 'Niang Ibrahima', role: 'Chargé SI', linkedln: 'https://www.linkedin.com/in/ibrahima-niang-29625a245/', mail: 'iniang@ensc.fr', photo: '/images/PhotosMandat/Ibra.jpg' };
  const listeSI = [simon, niang];
  //Pole Event
  const poleEvent = [agathe];

  return (
    <div>
      <Header />
      <PhotoEquipe />
      <MotDuPresident />
      <NosValeursHistoire />
      <NotreHistoire />
      <div className="flex flex-col gap-[84px] pl-[5.6vw]">
        <PoleMandat listeMembres={listePres} pole="Presidence" />
        <PoleMandat listeMembres={listeDevCo} pole="Développement Commercial" />
        <PoleMandat listeMembres={listeTresorerie} pole="Trésorerie" />
        <PoleMandat listeMembres={listeQualite} pole="Qualité" />
        <PoleMandat listeMembres={listeCommunication} pole="Communication" />
        <PoleMandat listeMembres={listeSI} pole="Système d'Information" />
        <PoleMandat listeMembres={poleEvent} pole="Evènementiel" />
      </div>
      <Footer />
    </div>
  );
}

export default Mandat;
