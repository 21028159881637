import { React } from 'react';

// Importzation des composants
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ChiffresCles from '../components/ChiffresCles';
import AnciensClients from '../components/AnciensClients';
import PourquoiNous from '../components/PourquoiNous';
import TitreI2C from '../components/TitreI2C';
import NotreJunior from '../components/NotreJunior';
import EtatpesDuProjet from '../components/EtapeDuProjet';
import NosPartenaires from '../components/NosPartenaires';
import NotreOffre from '../components/NotreOffre';

function Home() {
  const naviagte = useNavigate();

  return (
    <div>
      <Header variant="light" />
      <div>
        <div className="h-[650px] bg-gray-900 flex flex-col justify-between">
          <div className="h-full flex flex-col justify-center items-evenly gap-52">
            <h1 className="w-auto text-center text-5xl font-extrabold text-white">Placer l'Homme au coeur de l'innovation</h1>
            {/* Intégrer le backgound particules.js */}
            <div className="flex flex-row justify-evenly">
              <button
                className="bg-white text-sky-800 py-4 px-5 font-bold rounded-lg"
                onClick={() => {
                  document.getElementById('notre_offre').scrollIntoView({ behavior: 'smooth' });
                }}>
                Notre offre de prestation
              </button>
              <button className="bg-white text-sky-800 py-4 px-5 font-bold rounded-lg" onClick={() => naviagte('/contact')}>
                Prendre un rendez-vous
              </button>
            </div>
          </div>
          <img src="./others/elipse.png" alt="" className="relative w-screen" />
        </div>
        {/* <HautDePage /> */}
        <TitreI2C />
        <div className="flex flex-col justify-even gap-[76px] ">
          <NotreJunior />
          <ChiffresCles />
          <NosPartenaires />
          <EtatpesDuProjet />
          <NotreOffre />
          <AnciensClients />
          <PourquoiNous />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
