import React from 'react';
import BoutonPrincipal from './BoutonPrincipal';

function NotreJunior() {
  return (
    <div className="flex justify-center items-center mt-[80px]">
      <div className="relative left-[2.6vw] bg-[#e3f2fd] w-[84%] h-[461px] flex flex-row">
        <img src="./images/equipe.png" alt="" className="relative right-[2.6vw] -top-[80px] " />
        <div className="relative left-[8.5vw] flex flex-col justify-center items-center gap-3 w-[36vw]">
          <h3 className="text-[#016089] text-3xl font-semibold">Notre Junior-Entreprise</h3>
          <p className="text-lg font-normal">La Junior-Entreprise de l’ENSC, i2c, met son expertise en cognitique et sciences de l’ingénieur au service de vos projets. Depuis sa création, i2c se distingue par son approche unique qui conjugue expertise humaine et innovation technologique. Nous intervenons dans divers domaines : ingénierie (création de sites web, d'applications, de bases de données), conseil (audits ergonomiques, analyses statistiques) et cognitique (conception centrée utilisateur, gestion des connaissances, UI/UX).</p>
            <BoutonPrincipal titre={'En savoir plus'} />
        </div>
      </div>
    </div>
  );
}

export default NotreJunior;
