import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Dropdown({ name, liste, variant }) {
  const [hidden, setHidden] = useState(true);
  const [isClicked, setIsClicked] = useState(false);
  const navigate = useNavigate();

  const variantStyles = {
    light: {
      text: 'text-black',
    },
    dark: {
      text: 'text-white',
    },
  };

  const styles = variantStyles[variant] || variantStyles.light;

  return (
    <div>
      <button
        onMouseEnter={() => {
          setHidden(false);
        }}
        onMouseLeave={() => {
          setHidden(true);
        }}
        onClick={() => {
          setIsClicked(!isClicked);
        }}
        class={`${styles.text}`}>
        {name}
      </button>
      {(!hidden || isClicked) && (
        <div
          class="absolute "
          onMouseEnter={() => {
            setHidden(false);
          }}
          onMouseLeave={() => {
            setHidden(true);
          }}>
          <ul class="relative z-10 w-[187px] h-[150px] p-3 bg-light-grey flex flex-col justify-around items-start rounded-2xl shadow-xl rounded-tl-none -translate-x-4 translate-y-3">
            {liste.map((element, index) => (
              <li
                key={index}
                onClick={() => {
                  navigate(element[1]);
                }}
                class="px-4 py-2 hover:bg-blue-100 w-[162px] h-auto text-ms rounded-lg rounded-tl-0 cursor-pointer">
                {element[0]}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
export default Dropdown;
