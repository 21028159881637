import React from 'react';
import { useNavigate } from 'react-router-dom';

function ButtonNavBar({ name, lien, variant = 'light' }) {
  const navigate = useNavigate();

  const variantStyles = {
    light: {
      button: 'text-black border-white hover:border-sky-800 hover:text-sky-800',
    },

    dark: {
      button: 'text-white border-sky-800 hover:border-white',
    },
  };

  const styles = variantStyles[variant] || variantStyles.light;

  return (
    <button onClick={() => navigate(lien)} className={`${styles.button} py-2 z-50 border-b-2`}>
      {name}
    </button>
  );
}

export default ButtonNavBar;
