import React, {useRef} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './Contact.css';
// import emailjs from 'emailjs-com';

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // emailjs.sendForm('service_ljqaf2c', 'template_ts86duk', form.current, 'moaJ5y9XS9yWwFZLz')
    //   .then((result) => {
    //       console.log(result.text);
    //   }, (error) => {
    //       console.log(error.text);
    //   });
  };
  return (
    <div className="">
      <Header />
      <div className="contact">
        <div className="contact_image">
          <img src="./images/woman_working.jpg" alt="contact" />
          <div className="text-container">
            <h1 id="bleu">Contactez</h1>
            <h1>nous !</h1>
          </div>
          <h2>Vous avez une question ?</h2>
        </div>
        <div className="contact__container">
          <div className="infos">
            <div className="info">
              <img src="./images/logo_tel.svg" alt="phone" />
              <div>
                <h3>Numéro de téléphone</h3>
                <p>+33 7 82 70 43 44</p>
              </div>
            </div>
            <div className="info">
              <img src="./images/logo_mail.svg" alt="email" />
              <div>
                <h3>Adresse Email</h3>
                <p>contact@junior-i2c.com</p>
              </div>
            </div>
            <div className="info">
              <img src="./images/logo_adresse.svg" alt="address" />
              <div>
                <h3>Adresse</h3>
                <p>109 avenue Roul, Talence 33400</p>
              </div>
            </div>
          </div>
          <div className="contact__form">
            <h1>Envoyez un message </h1>
            <form ref={form} onSubmit={sendEmail}>
              <div>
                <input type="text" name="name" placeholder="Nom" />
              </div>
              <div>
                <input type="text"  name="prenom" placeholder="Prénom" />
              </div>
              <div>

                <input type="text"  name="phone" placeholder="Numéro de téléphone" />
              </div>
              <div>
                <input type="email" name="email" placeholder="Email" />
              </div>
              <div>
                <input type="text"  name="project" placeholder="Nom du projet" />
              </div>
              <div className="hidden-input">
                <input type="text" placeholder="Sujet" />
              </div>
              <div className="message">
                <textarea name="message" placeholder="Message"></textarea>
              </div>
              <button type="submit" className="contact__sendButton">Envoyer</button>
            </form>
          </div>

        </div>
        <div className="map">
          <h1>Trouvez nous sur google maps</h1>
          <p>A coté de l'arrêt de tram Béthanie</p>
          <img src="./images/map.png" alt="map" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;