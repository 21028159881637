import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.css';
function Footer() {

  const navigate = useNavigate();
  return (
    
    <div id="footerContainer">
      <div id="logo">
        <embed src="./images/logo_blanc.svg" type="" />
      </div>
      <div id="tout">

        <div id="buttons">
          <button class="buttonBlanc" onClick={() => {
                navigate('/contact');
                window.scrollTo(0, 0);}}>Faire un devis</button>
          <a href="./plaquette/Plaquette2025.pdf" download className="buttonBlanc" id="plaquette">
            Télecharger la plaquette
          </a>
        </div>
        <div id="reseaux">
          <h2>Suivez-nous sur les réseaux sociaux !</h2>
          <div>
            <a href='https://www.instagram.com/juniori2c/' target="_blank" rel="noreferrer">
            <img src="images\Instagram_logo.png" alt="logo_instagram" />
            </a>
            <a href='https://www.linkedin.com/company/junior-i2c/' target="_blank" rel="noreferrer">
            <img src="images\LinkedIn_logo.png" alt="logo_linkledln" />
            </a>
            <a href='https://www.youtube.com/@ingenierieetconseilencogni7786' target='_blank' rel="noreferrer"> 
            <img src="images\Youtube_logo.png" alt="logo_Youtube" id="ytb" />
            </a>
          </div>
        </div>
        <div id="footerFooter">
          <div id="coordonnees">
            <p>106 av.Raoul 33400 Talence</p>
            <p>junior-i2c@ensc.fr</p>
          </div>
          <div id="mentions">
            <p>Politique de confidentialité</p>
            <p>Mentions légales</p>
            <p>Conditions Générales de Ventes(CGV)</p>
            <p id="copyright">@copyright fait par l'équipe i2site</p>
          </div>
          <div>
            <button id="espaceMembre" class="buttonBleu" onClick={() => {
                navigate('/Page404');}}>Espace membre</button>
            <button class="buttonBleu" onClick={() => {
                navigate('/contact');
                }}>Plan du site</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
