import React from 'react';

import CarteChiffreCle from './CarteChiffreCle';

function ChiffresCles() {
  return (
    <div id="chiffres_cles" className="overflow-hidden">
      <div className="flex flew-row justify-evenly">
        <CarteChiffreCle logo="./icons/calendar_logo.svg" titre="Depuis" chiffre="2007" />
        <CarteChiffreCle logo="./images/student.svg" titre="Étudiants mobilisables" chiffre="240" />
        <CarteChiffreCle logo="./images/document.svg" titre="Études réalisées" chiffre="50" />
      </div>
    </div>
  );
}

export default ChiffresCles;
