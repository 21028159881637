
import './NosValeursHistoire.css';

function NosValeursHistoire() {
    return (
        <div className="NosValeursHistoire">
            <div className="titre">
                <h2>Nos <span id="Valeurs">valeurs</span></h2>
            </div>
            <div className='valeurs'>
                <div className="Humain">
                    <img src='../images/humain.svg' alt='humain' />
                    <h3>Humain</h3>
                </div>
                <div className="Partage">
                    <img src='../images/partage.svg' alt='innovation' />
                    <h3>partage</h3>
                </div>
                <div className="Innovation">
                    <img src='../images/innovation.svg' alt='innovation' />
                    <h3>Innovation</h3>
                </div>
            </div>
    
        </div>

    )

}

function Date({date, texte}) {
    return(
        <div className="Date">
            <div className="trait">
                <img src='../images/trait.svg' alt='trait' />
            </div>
            <div className='histoire'>
                <div className="datenumero">
                    <h3>{date}</h3>
                </div>
                <div className="texte">
                    <p>{texte}</p>
                </div>
            </div> 
        </div>

    )
}
function NotreHistoire(){
    const date1 = {date: "2007", texte: "Création de la junior entreprise i2c"};
    const date2 = {date: "2008", texte: "Passage Junior Création"};
    const date3 = {date: "2010", texte: "Passage Junior Entreprise"};
    const date4 = {date: "2011", texte: "Laureat du prix du meilleur espoir"};
    const date5 = {date: "2025", texte: "L'histoire continue"};
    const listeDates = [date1, date2, date3, date4, date5];
    return(
        <div className='divContainer'>
            <div className="titre">
                <h2>Notre <span id="Histoire">histoire</span></h2>
            </div>
        <div className="NotreHistoire">
            {listeDates.map((date) => <Date date={date.date} texte={date.texte}/>)}
        </div>
        <div className="notreMandat">
            <h2>Notre <span id="Histore">Mandat</span></h2>
        </div>
        </div>
    )
}

export { NosValeursHistoire, NotreHistoire };
