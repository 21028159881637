import React from "react";

function TitreI2C() {
  return (
    <div className="font-extrabold text-center text-[40px] mb-[30px] mt-[20px]">
      <span className="text-bleu-je">I</span>
      <span className="text-[#464543]">ngénierie et </span>
      <span className="text-bleu-je">C</span>
      <span className="text-[#464543]">onseil en </span>
      <span className="text-bleu-je">C</span>
      <span className="text-[#464543]">ognitique</span>
    </div>
  );
}

export default TitreI2C;