import React from 'react';
import './NotreMandat.css';

function PhotoEquipe() {
  return (
    <div className="photoEquipe">
      <h2>
        Qui sommes <span id="Nous">nous</span> ?
      </h2>
      {/* <img src="../images/equipe.png" alt="equipe" /> */}
    </div>
  );
}

function MotDuPresident() {
  return (
    <div className="motDuPresident">
      <div className="texte">
        <h2>
          Le mot du <span id="President">Président</span>
        </h2>
        <p>Je suis très fier de présider cette équipe dynamique et engagée. Nous travaillons chaque jour pour atteindre nos objectifs et réaliser notre mission avec passion et détermination. </p>
      </div>

      <div className="photo">
        <img src="../images/PhotosMandat/Clement.jpg" alt="president" id="pres" />
        <img src="../images/bgphoto.png" alt="background" id="bg" />
      </div>
    </div>
  );
}
export { PhotoEquipe, MotDuPresident };
