import { React } from 'react';

import OffrePrestaGrand from '../components/OffrePrestaGrand';

function NotreOffre() {
  var listeOffres = [
    { titre: 'Cognitique', image: './images/prestaCog', description: 'La Junior-Entreprise de l’ENSC, i2c, met son expertise en cognitique et sciences de l’ingénieur au service de vos projets. ', lien: './cognitique' },
    { image: './images/prestaInge', titre: 'Ingénierie', description: 'La Junior-Entreprise de l’ENSC, i2c, met son expertise en cognitique et sciences de l’ingénieur au service de vos projets. ', lien: './ingenierie' },
    { titre: 'Conseil', image: './images/prestaConseil', description: 'La Junior-Entreprise de l’ENSC, i2c, met son expertise en cognitique et sciences de l’ingénieur au service de vos projets. ', lien: './conseil' },
  ];
  var offrePrestation = (
    <div className="flex flex-row justify-center items-center gap-8">
      <OffrePrestaGrand image={listeOffres[0].image + 'Grand.png'} titre={listeOffres[0].titre} description={listeOffres[0].description} lien={listeOffres[0].lien} />
      <OffrePrestaGrand image={listeOffres[1].image + 'Grand.png'} titre={listeOffres[1].titre} description={listeOffres[1].description} lien={listeOffres[1].lien} />
      <OffrePrestaGrand image={listeOffres[2].image + 'Grand.png'} titre={listeOffres[2].titre} description={listeOffres[2].description} lien={listeOffres[2].lien} />
    </div>
  );
  return (
    <div>
      <div id="notre_offre" className="flex flex-col justify-center gap-[21px] ">
        <h2 className="text-dark-grey text-[40px] font-extrabold text-center">
          Notre <span className="text-bleu-je">offre</span> de prestation
        </h2>
        {offrePrestation}
      </div>
    </div>
  );
}

export default NotreOffre;
