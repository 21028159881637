import React from 'react';

function ListePartenaires() {
  const partenaires = [
    ['CNJE', './images/logo_cnje.png'],
    ['BNP Paribas', './images/logo_bnp.png'],
    ['Géoenvireau études', './images/logo_geo.png'],
    ['Talence innovation sud développement', './images/logo_talence.png'],
  ];

  const ListePartenaires = partenaires.map((partenaire) => (
    <li>
      <img src={partenaire[1]} alt={partenaire[0]} />
    </li>
  ));
  return (
    <div>
      <ul className="flex flex-row gap-[74px] justify-center items-center">{ListePartenaires}</ul>
    </div>
  );
}

export default ListePartenaires;
