import React from 'react';

function BanniereTitre({ titre, liste }) {
  const elements = liste.map((element, index) => {
    if (index === liste.length - 1) {
      return <li key={index}>{element}</li>;
    }
    return <li key={index}>{element}</li>;
  });

  return (
    <div className="bg-[#F6FAFC] h-[484px] flex flex-col justify-center">
      <div className="text-[#464543] text-3xl font-semibold ml-16">
        <p className="">i2c vous accompagne dans</p>
        <div className='flex flex-row gap-2'>
          <p className="">{titre}</p>
          <div className="h-[36px] overflow-hidden">
            <ul className=" text-bleu-je flex flex-col animate-slide-up">{elements}</ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BanniereTitre;
