import React from 'react';
import BoutonPrincipal from './BoutonPrincipal';

function PourquoiNous() {
  return (
    <div className="">
      <h2 className="text-dark-grey text-[40px] font-extrabold text-center md:text-4xl mb-20">
        Pourquoi <span className="text-bleu-je">nous</span> ?
      </h2>
      <div className="bg-custom-gray flex flex-row items-center gap-8 w-[84vw] relative left-[6.7vw]">
        {/* Texte */}
        <div className="text-gray-700 lg:w-1/2 flex flex-col items-center">
          <h3 className="text-2xl text-[#016089] font-semibold mb-4">Choisir i2c</h3>
          <p className="ml-11 leading-relaxed">
            La Junior-Entreprise de l'ENSC, i2c, met son expertise en cognitive et sciences de l’ingénieur au service de vos projets. Depuis sa création, i2c se distingue par son approche unique qui conjugue expertise humaine et innovation technologique. Nous intervenons dans divers domaines :
            <ul className="list-disc list-inside ml-4 mt-2 space-y-2">
              <li>Ingénierie (création de sites web, applications, bases de données)</li>
              <li>Conseil (audits ergonomiques, analyses statistiques)</li>
              <li>Cognitique (conception centrée utilisateur, UI/UX, gestion des connaissances)</li>
            </ul>
          </p>
          {/* Bouton */}
          <BoutonPrincipal titre={'En savoir plus'} />
        </div>
        {/* Image */}
        <div className="lg:w-1/2 relative">
          <img src="./images/equipe.png" alt="Team i2c" className="rounded-lg shadow-md -mt-14 mb-11 translate-x-24" />
        </div>
      </div>
    </div>
  );
}

export default PourquoiNous;
