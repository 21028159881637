import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Devis from './pages/Devis';
import Page404 from './pages/Page404';
import Mandat from './pages/Mandat';
import CNJE from './pages/CNJE';
import ENSC from './pages/ENSC';
import Ingenierie from './pages/Ingenierie';
import Conseil from './pages/Conseil';
import Cognitique from './pages/Cognitique';

function App() {
  return (
    <div className="min-h-screen w-full">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/devis" element={<Devis />} />
          <Route path="*" element={<Page404 />} />
          <Route path="/mandat" element={<Mandat />} />
          <Route path="/cnje" element={<CNJE />} />
          <Route path="/ensc" element={<ENSC />} />
          <Route path="/ingenierie" element={<Ingenierie />} />
          <Route path="/conseil" element={<Conseil />} />
          <Route path="/cognitique" element={<Cognitique />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
