import React from 'react';

import Header from '../components/Header';
import BanniereTitre from '../components/BanniereTitre';
import InfoJuniorDroit from '../components/InfoJuniorDroit';
import InfoJuniorGauche from '../components/InfoJuniorGauche';
import Footer from '../components/Footer';

function Conseil() {
  return (
    <div>
      <Header />
      {/* Encadré pour le titre */}
      <BanniereTitre titre="la réalisation d'" liste={['audit ergonomique', 'audit en accessibilité', 'analyse statistique', 'audit ergonomique']} />
      {/* Contenu de la page */}
      <div className="bg-white">
        <h2 className="text-dark-grey text-[40px] font-semibold text-center pt-10">
          Notre offre de prestation en <span className="text-bleu-je">Conseil</span>
        </h2>
        <InfoJuniorDroit
          redirection="/"
          image="./images/stats.png"
          titre="Analyse statistique"
          texte="Nous vous accompagnons dans l’analyse statistique de vos données pour en extraire des informations clés. Que ce soit pour identifier les tendances du marché, optimiser vos processus internes ou orienter vos décisions stratégiques, nous mettons notre expertise à votre service. Grâce à notre maîtrise des méthodes d’analyse avancées et à nos compétences en facteurs humains, nous donnons du sens aux données pour les rendre accessibles et compréhensibles par tous, facilitant ainsi leur exploitation au sein de vos équipes."
        />
        <InfoJuniorGauche redirection="/" image="./images/audit.png" titre="Audit ergonomique" texte="Notre audit ergonomique vise à optimiser l’expérience utilisateur sur vos sites web, applications mobiles et interfaces digitales. Nos intervenants analysent en détail votre produit pour identifier les points d’amélioration et proposer des solutions ergonomiques adaptées. Leur approche permet d’améliorer l’accessibilité, la satisfaction des utilisateurs et la performance globale de vos produits. " />
        <InfoJuniorDroit redirection="/" image="./images/accessibilite.png" titre="Conseil en accesiblité et handicap" texte="Notre conseil en accessibilité et handicap vise à rendre vos produits et services accessibles à tous. Nos intervenants collaborent avec vos équipes pour s’assurer que vos produits numériques respectent les normes d'accessibilité. Grâce à leur sensibilité aux besoins des personnes en situation de handicap, ils proposent des solutions inclusives garantissant une expérience optimale pour tous les utilisateurs." />
        <Footer />
      </div>
    </div>
  );
}

export default Conseil;
