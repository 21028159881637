import React from 'react';

import BoutonPrincipal from './BoutonPrincipal';

function InfoJuniorDroit({ titre, texte, boutton, image }) {
  return (
    <div className="flex justify-center items-center pt-[80px] mt-[80px]">
      <div className="relative bg-[#e3f2fd] w-[84vw] h-[461px] flex flex-row gap-[6.2vw] pl-[4vw]">
        <div className="relative flex flex-col justify-center items-center gap-3 w-[42vw]">
          <h3 className="text-[#016089] text-3xl font-semibold">{titre}</h3>
          <p className="text-lg font-normal">{texte}</p>
          <BoutonPrincipal titre={'En savoir plus'} lien={boutton} />
        </div>
        <img src={image} alt="" className="relative -top-[80px] left-10 size-[461px]" />
      </div>
    </div>
  );
}

export default InfoJuniorDroit;
