import React from 'react';

import './CadreMandat.css';

function CadreMandat({nom,role,linkedln,mail,photo}){
    const mailto = "mailto:"+mail;
    return(
        <div>
            <div className='prenomRole'>
                <p id="prenom">{nom} </p>
                <p id="role">/ {role}</p>
            </div>
            <div className='photo'>
                <img src={photo} alt="la personne"/>
            </div>
            <div className='liens'>
                <a href={mailto}>
                <img src = "images/miniMail.svg" alt="lienMail"/>
                </a>
                <a href={linkedln} target="_blank" rel="noreferrer">
                <img src="images/miniLink.svg" alt="lienLinkledln"/>
                </a>
            </div>
        </div>
    )



}
export default CadreMandat;