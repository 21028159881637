import React from 'react';

function CarteChiffreCle({ logo, titre, chiffre }) {
  return (
    <div className="flex flex-col justify-between items-center h-[190px] w-[250px]">
      <embed src={logo} type="" />
      <h4 className="text-sky-800 text-xl font-bold">{titre}</h4>
      <div className="overflow-hidden h-10 flex flex-row justify-center items-center">
        <span className="text-bleu-je text-xl font-bold translate">{chiffre}</span>
      </div>
    </div>
  );
}
export default CarteChiffreCle;
