import React from 'react';

import ListePartenaires from '../components/ListePartenaires';

function NosPartenaires() {
  return (
    <div id="partenaires" className="relative bg-[url('../public/images/bg_skyline.png')] bg-cover bg-bottom h-[472px] ">
      <div className="relative top-[54px] flex flex-col gap-[30px] ">
        <h3 className="pt-5 pb-3 text-[#016089] text-center text-3xl font-semibold">Nos partenaires</h3>
        <div className='pt-8'>
          <ListePartenaires />
        </div>
      </div>
    </div>
  );
}

export default NosPartenaires;
