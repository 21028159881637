import React from 'react';
import BoutonPrincipal from './BoutonPrincipal';

function OffrePrestaGrand({ image, titre, description, lien }) {
  return (
    <div class="w-[347px] h-[431px] pb-5 bg-white rounded-[5px]">
      <img src={image} alt="" />
      <div className="">
        <div className="relative top-6 flex flex-col items-center justify-center gap-5">
          <h4 className="relative -left-14 text-dark-grey text-2xl font-bold">{titre}</h4>
          <p class="w-[221px] text-[#6d6767] text-[13px] font-normal">{description} </p>
          <div className='relative top-5'>
            <BoutonPrincipal titre="En savoir plus" lien={lien}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OffrePrestaGrand;
