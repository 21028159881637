import React from 'react';

import Header from '../components/Header';
import BanniereTitre from '../components/BanniereTitre';
import InfoJuniorDroit from '../components/InfoJuniorDroit';
import InfoJuniorGauche from '../components/InfoJuniorGauche';
import Footer from '../components/Footer';

function Cognitique() {
  return (
    <div>
      <Header />
      {/* Encadré pour le titre */}
      <BanniereTitre titre="la mise en place de " liste={['knowledge management', 'méthodes CCU', "méthode d'UX/UI design", 'knowledge management']} />
      {/* Contenu de la page */}
      <div className="bg-white">
        <h2 className="text-dark-grey text-[40px] font-semibold text-center pt-10">
          Notre offre de prestation en <span className="text-bleu-je">Cognitique</span>
        </h2>
        <InfoJuniorDroit redirection="/" image="./images/MASK.png" titre="Knowledge Management" texte="Nous aidons les entreprises à structurer, formaliser et transmettre leurs savoirs. Nos experts, formés à la méthode MASK, vous accompagnent dans la mise en place de processus de gestion des connaissances pour assurer leur pérennité, par exemple, par le biais de livres de connaissance facilitant le partage et la capitalisation des savoirs au sein de votre organisation." />
        <InfoJuniorGauche
          redirection="/"
          image="./images/CCU.png"
          titre="Méthodes de Conception Centrée Utilisateur"
          texte="Nos méthodes de Conception Centrée Utilisateur, telles que la veille technologique, les questionnaires, les entretiens et les tests utilisateurs, sont rigoureusement appliquées par nos intervenants. Grâce à notre approche, nous proposons des idées novatrices basées sur les retours des utilisateurs en prenant en compte leurs besoins tout au long du projet. En d’autres termes, si un client a une idée précise au départ, elle ne correspondra pas forcément aux attentes réelles des utilisateurs une fois concrétisée. "
        />
        <InfoJuniorDroit
          redirection="/"
          image="./images/UI.png"
          titre="UX/UI Design "
          texte="L'UX/UI Design est l'une de nos spécialités. L'UX (expérience utilisateur) englobe l’ensemble des interactions qu’un utilisateur a avec un produit ou un service. L’UI (interface utilisateur), quant à elle, concerne la partie visuelle et interactive, incluant la disposition des éléments, les couleurs, les polices et les boutons. Le maquettage, réalisé par nos intervenants, est essentiel pour garantir le succès d’un produit, qu’il s’agisse d’un site web ou d’une application mobile, en assurant une expérience utilisateur fluide et optimale."
        />
        <Footer />
      </div>
    </div>
  );
}

export default Cognitique;
