// filepath: /c:/Users/ibreu/OneDrive/Documents/2A/siteJE/i2site/src/components/Jauge.js
import React from 'react';
import './Jauge.css';
import EtapeJauge from './EtapeJauge';

function Jauge() {
  const etapes = ['Prise de contact avec i2c', 'Rendez-vous client', 'Mise en place du projet', 'Selection des intervenants', 'Réalisation du projet', 'Retours et garantie'];

  const liste = etapes.map((etape, index) => <EtapeJauge numero={index + 1} text={etape} key={index} />);

  return (
    <div className="h-[170px] flex flex-col gap-[26px]">
      <ul className="w-[75vw] relative flex flex-row left-[15vw] gap-[2vw] group">{liste}</ul>
      <div className="flex flex-row justify-center">
        <div className="w-[69vw] h-[23px] relative">
          <div className="w-[69vw] h-2 top-[8px] absolute bg-[#b4afaf] rounded-[50px]"></div>
          <div className="animated-trait-fill"></div>
          <div className="circle-container">
            <div className="animated-circle bg-bleu-je"></div>
            <div className="animated-circle"></div>
            <div className="animated-circle"></div>
            <div className="animated-circle"></div>
            <div className="animated-circle"></div>
            <div className="animated-circle"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Jauge;