import React from 'react';
import { useNavigate } from 'react-router-dom';

function BoutonPrincipal({ titre, lien }) {
  const navigate = useNavigate();
  return (
    <button className="group relative  text-bleu-je hover:text-white text-center py-[8px] px-[18px] text-xs font-medium overflow-hidden hover:bg-bleu-je duration-200" onClick={() => {navigate(lien); window.scrollTo(0, 0);}}>
      <div className="relative z-10 duration-100">{titre}</div>
      <div className="bg-bleu-je absolute top-0 right-0 h-1 w-4 group-hover:w-20 duration-200"></div>
      <div className="bg-bleu-je absolute top-0 right-0 h-4 w-1 group-hover:h-10 duration-200"></div>
      <div className="bg-bleu-je absolute bottom-0 left-0 h-4 w-1 group-hover:h-10 duration-200"></div>
      <div className="bg-bleu-je absolute bottom-0 left-0 h-1 w-4 group-hover:w-20 duration-200"></div>
    </button>
  );
}

export default BoutonPrincipal;
