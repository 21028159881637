import React from 'react';

import Header from '../components/Header';
import BanniereTitre from '../components/BanniereTitre';
import InfoJuniorDroit from '../components/InfoJuniorDroit';
import InfoJuniorGauche from '../components/InfoJuniorGauche';
import Footer from '../components/Footer';

function Ingenierie() {
  return (
    <div>
      <Header />
      {/* Encadré pour le titre */}
      <BanniereTitre titre="le développement de votre " liste={['site web', 'base de données', 'intelligence artificielle', 'application', 'site web']} />
      {/* Contenu de la page */}
      <div className="bg-white">
        <h2 className="text-dark-grey text-[40px] font-semibold text-center pt-10">
          Notre offre de prestation en <span className="text-bleu-je">Ingénierie</span>
        </h2>
        <InfoJuniorDroit
          boutton="/contact"
          titre="Développement de sites web, d’applications mobiles et de logiciels"
          image="./images/site_web.jpg"
          texte="Chez i2c, nous créons des sites web modernes, adaptés à tous les écrans et optimisés pour une navigation fluide. Chaque projet est conçu sur mesure avec des fonctionnalités interactives répondant aux besoins spécifiques de nos clients. Nos intervenants développent également des applications mobiles pour iOS, Android et le web. Grâce à une formation axée sur la pratique, nos intervenants acquièrent une expertise solide à travers divers projets et cours. Enfin, nous réalisons des logiciels sur mesure en utilisant des technologies adaptées à vos besoins. Nos solutions s’intègrent facilement à vos systèmes existants."
        />
        <InfoJuniorGauche boutton="/contact" titre="Création et refonte de base de données" image="./images/bdd.png" texte="Nous concevons et optimisons vos bases de données pour une récupération rapide et efficace des informations. Nous en assurons également la maintenance et la surveillance afin de prévenir les ralentissements et garantir leur performance. Enfin, nous les intégrons directement à vos projets pour une gestion fluide et adaptée à vos besoins." />
        <InfoJuniorDroit boutton="/contact" image="./images/IA4.png" titre="Intelligence artificielle et algorithmie" texte="Nous maîtrisons les concepts et technologies de l’IA, ce qui nous permet d’intégrer efficacement ce domaine dans vos projets. Nous concevons également des chatbots et des interfaces IA pour enrichir l’expérience utilisateur. Grâce à notre expertise en algorithmie, nous développons des solutions sur mesure, optimisant vos processus internes et facilitant la prise de décision automatisée." />
        <Footer />
      </div>
    </div>
  );
}

export default Ingenierie;
