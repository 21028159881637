import React from 'react';
import Header from '../components/Header';

function Page404() {
  return (
    <div>
      <Header />
      <body className="h-[83vh] bg-sky-800 text-white">
        <div className="flex justify-center items-center h-[88%]">
          <span className=" text-9xl ">404</span>
        </div>
        <div className="relative left-10 w-[800px]">
          <p className="text-base relative w-[100%]">Oups, cette page est introuvable.</p>
          <p className="text-base">Retournez à l'accueil pour découvrir nos services en ingénierie et conseil en cognitique.</p>
        </div>
      </body>
    </div>
  );
}

export default Page404;
