import React from 'react';

function AnciensClients() {
  const Clients = ['./images/logo_thales.png', './images/airbus.png', './images/lectra_logo.png', './images/anr_logo.png'];

  const listeClients = Clients.map((client) => {
    return (
      <li>
        <img src={client} alt="" />
      </li>
    );
  });

  return (
    <div>
      <h2 className="text-dark-grey text-[40px] font-extrabold text-center">
        Ils nous ont fait <span className="text-bleu-je">confiance</span>
      </h2>
      <div
        x-data="{}"
        x-init="$nextTick(() => {
        let ul = $refs.logos;
        ul.insertAdjacentHTML('afterend', ul.outerHTML);
        ul.nextSibling.setAttribute('aria-hidden', 'true');
    })"
        class="w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]">
        <ul className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll">{listeClients}</ul>
        <ul className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll" aria-hidden="true">
          {listeClients}
        </ul>
      </div>
    </div>
  );
}

export default AnciensClients;
