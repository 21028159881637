import { React } from 'react';
import { useNavigate } from 'react-router-dom';

import Dropdown from './Dropdown';
import ButtonNavBar from './ButtonNavBar';

function Header({ variant = 'light' }) {
  const navigate = useNavigate();

  const variantStyles = {
    light: {
      devis: 'bg-sky-800 text-white border-white hover:bg-light-grey hover:text-sky-800 hover:border-sky-800',
      header: 'bg-white',
      logo: './images/logo_noir.svg',
    },

    dark: {
      devis: 'bg-white text-sky-800 border-sky-800 hover:bg-sky-800 hover:text-white hover:border-white',
      header: 'bg-sky-800',
      logo: './images/logo_blanc.svg',
    },
  };

  const styles = variantStyles[variant] || variantStyles.light;

  return (
    <header className={`${styles.header} py-5`}>
      <div class="flex justify-around items-center">
        <div onClick={() => navigate('/')}>
          <div onClick={() => navigate('/')} className="-z-10">
            <embed src={styles.logo} alt="logo d'i2c" class="w-20" />
          </div>
        </div>
        <div class="flex flex-row justify-around items-center h-14 w-[640px]">
          <ButtonNavBar name="Accueil" lien="/" variant={variant} />
          <div class="flex flex-row group">
            <Dropdown
              name="Nos services"
              liste={[
                ['Ingénierie', '/ingenierie'],
                ['Conseil', '/conseil'],
                ['Cognitique', '/cognitique'],
              ]}
              variant={variant}
            />
            <img src="./icons/menu item arrow.svg" alt="" class="px-3 -rotate-90 group-hover:rotate-0 ease-linear duration-75" />
          </div>
          {/*
          <div class="flex flex-row group">
            <Dropdown
              name="Qui sommes nous ?"
              liste={[
                ['Notre mandat', '/mandat'],
                ['La CNJE', '/cnje'],
                ["L'ENSC", '/ensc'],
              ]}
              lien="/nous"
              variant={variant}
          
            />
            <img src="./icons/menu item arrow.svg" alt="" class="px-3 -rotate-90 group-hover:rotate-0 ease-linear duration-75" />
          </div>
          */}
          <ButtonNavBar name="Qui sommes nous ?" lien="/mandat" variant={variant} />
          <ButtonNavBar name="Nous contacter" lien="/contact" variant={variant} />
        </div>
        <div class="flex flex-row justify-around items-center w-[245px]">
          <div
            id="devis"
            class={`${styles.devis} py-4 px-6 rounded-lg border-2  hover:border-2 cursor-pointer`}
            onClick={() => {
              navigate('/contact');
            }}>
            <button>Devis</button>
          </div>
          <ButtonNavBar name="Espace membre" lien="/404" variant={variant} />
        </div>
      </div>
    </header>
  );
}

export default Header;
